.about {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
    width: 30%;
    align-items: center;
  }

  .about-text {
    padding-left: 1em;
  }