
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  }
  
.error-content {
  width: 100%;
  display: block;
  margin: 0 20%;
}

.error-nav-column {
  width: 20%;
  padding-right: 5%;
  padding-top: 10%;
  display: inline-block;
  vertical-align: top;
}

.error-box-column {
  width: 75%;
  display: inline-block;
}

.create-content {
  width: 100%;
  display: block;
  margin: 0 20%;
}

.create-select-column {
  width: 20%;
  padding-right: 5%;
  padding-top: 10%;
  display: inline-block;
  vertical-align: top;
}

.create-box-column {
  width: 75%;
  display: inline-block;
}

@media only screen and (max-device-width: 480px) {
  .error-content {
    width: 100%;
    display: block;
    margin: 0 5%;
  }
  .error-nav-column {
    width: 100%;
    padding-top: 10%;
    display: block;
  }
  
  .error-box-column {
    width: 100%;
    display: block;
  }

  .create-content {
    width: 100%;
    display: block;
    margin: 0 5%;
  }
  .create-select-column {
    width: 100%;
    padding-top: 10%;
    display: block;
  }
  
  .create-box-column {
    width: 100%;
    display: block;
  }
  
}