
  
  .function-debug-response {
    padding: 4%;
  }
  
  .function-debug-footer {
    font-size: 12px;
    text-align: right;
    border-radius: 0 0 0.875rem 0.875rem;
    background-color: #F6F7F9;
    padding: 16px;
  }
  
  
  @media only screen and (max-device-width: 480px) {

    
    .function-debug-response {
      padding: 9%;
      margin-top: 3%;
    }
  }